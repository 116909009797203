/*eslint-disable */
// import ImageModel from '../../Model/Image'
import request from "../../Utils/curl";
import Utility from "../../Utils/utility";

let Images = {
  /**
   * imageList
   */
  async imageList(context) {
    try {
      let post_data = new FormData();

      return await request
        .curl(context, "image_list", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageList() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageListCustomType
   */
  async imageListCustomType(context, imageObj) {
    try {
      let post_data = new FormData();
      for (const key in imageObj) {
        post_data.append(key, imageObj[key]);
      }
      return await request
        .curl(context, "image_list_custom_type", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageList() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageListProfileImages
   */
  async imageListProfileImages(context, moduleId, userId=null) {
    try {
      let post_data = new FormData();
      post_data.append("module_id", moduleId);
      
      if(userId) { 
        post_data.append('user_id', userId);
      }

      return await request
        .curl(context, "image_list_profile_images", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageListProfileImages() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageView
   */
  async imageView(context, moduleId) {
    try {
      let post_data = new FormData();
      post_data.append("module_id", moduleId);
      return await request
        .curl(context, "image_view", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageView() and Exception:",
        err.message
      );
    }
  },

  /**
   * image Add
   */
  async imageAdd(context, imageObj) {
    try {
      Utility.showLoader = true;
      Utility.loadingMsg = "Uploading....";
      let post_data = new FormData();

      for (let key in imageObj) {
        if (key === "image") {
          if (Array.isArray(imageObj[key])) {
            //sending the image one by one because in this way it get send as file
            // if sending whole image array at once then it will get send as string of array ie '['apple', 'banana']'
            imageObj[key].forEach((image) => {
              post_data.append(key, image);
            });
          }
        else {
            post_data.append(key, imageObj[key]);
          }
        }
        else if (key === "image_type") {
          post_data.append(key, JSON.stringify(imageObj[key]));
        }
        else {
          post_data.append(key, imageObj[key]);
        }
      }

      return await request
      .curl(context, "image_add", post_data)
      .then(async (response) => {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        return response;
      });
    }
    catch (err) {
      Utility.showLoader = false;
      Utility.loadingMsg = "";
      console.error(
        "Exception occurred at imageAdd() and Exception:",
        err.message
      );
    }
  },

  /**
   * image Profile Add
   */
  async imageProfileAdd(context, imageObj) {
    try {
      Utility.showLoader = true;
      Utility.loadingMsg = "Uploading....";
      let post_data = new FormData();

      for (let key in imageObj) {
        if (key === "image") {
          if (Array.isArray(imageObj[key])) {
            //sending the image one by one because in this way it get send as file
            // if sending whole image array at once then it will get send as string of array ie '['apple', 'banana']'
            for (let index in imageObj["image"]) {
              let pic = imageObj["image"][index];
              post_data.append(pic.image_type, pic.image.img, pic.image.name);
            }
          }
    else {
            post_data.append(key, imageObj[key]);
          }
        }
    else if (key === "image_type") {
          post_data.append(key, JSON.stringify(imageObj[key]));
        }
    else {
          post_data.append(key, imageObj[key]);
        }
      }

      return await request
      .curl(context, "image_add", post_data)
      .then(async (response) => {
        Utility.showLoader = false;
        Utility.loadingMsg = "";
        return response;
      });
    }
    catch (err) {
      Utility.showLoader = false;
      Utility.loadingMsg = "";
      console.error(
        "Exception occurred at imageAdd() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageEdit
   */
  async imageEdit(context, imageObj) {
    try {
      Utility.showLoader = true
      Utility.loadingMsg = "Uploading...."
      let post_data = new FormData();

      for (let key in imageObj) {
        if (key === "image") {
          if (Array.isArray(imageObj[key])) {
            // image is array of image
            imageObj[key].forEach((image) => {
              post_data.append(key, image);
            });
          }
    else {
            // when is there is only ome image
            post_data.append(key, imageObj[key]);
          }
        }
    else if (key === "image_type") {
          post_data.append(key, JSON.stringify(imageObj[key]));
        }
    else {
          post_data.append(key, imageObj[key]);
        }
      }

      return await request
        .curl(context, "image_edit", post_data)
        .then(async (response) => {
          Utility.showLoader = false;
          Utility.loadingMsg = "";
          return response;
        });
    }
    catch (err) {
      Utility.showLoader = false;
      Utility.loadingMsg = "";
      console.error(
        "Exception occurred at imageEdit() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageProfileUpdate
   */
  async imageProfileUpdate(context, imageObj) {
    try {
      Utility.showLoader = true
      Utility.loadingMsg = "Uploading...."
      let post_data = new FormData();

      for (let key in imageObj) {
        if (key === "image") {
          if (Array.isArray(imageObj[key])) {
            // image is array of image
            imageObj[key].forEach((image) => {
              post_data.append(key, image.img, image.name);
            });
          }
    else {
            // when is there is only ome image
            post_data.append(key, imageObj[key]);
          }
        }
    else if (key === "image_type") {
          post_data.append(key, JSON.stringify(imageObj[key]));
        }
    else {
          post_data.append(key, imageObj[key]);
        }
      }

      return await request
        .curl(context, "image_profile_update", post_data)
        .then(async (response) => {
          Utility.showLoader = false;
          Utility.loadingMsg = "";
          return response;
        });
    }
    catch (err) {
      Utility.showLoader = false;
      Utility.loadingMsg = "";
      console.error(
        "Exception occurred at imageEdit() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageDelete
   */
  async imageDelete(context, moduleId, imgType = 1) {
    try {
      let post_data = new FormData();

      post_data.append("module_id", moduleId);
      post_data.append("image_type", imgType);

      return await request
        .curl(context, "image_delete", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageDelete() and Exception:",
        err.message
      );
    }
  },

  /**
   * imageDelete
   */
  async imageDeleteByID(context, image_id) {
    try {
      let post_data = new FormData();

      post_data.append("image_id", image_id);

      return await request
        .curl(context, "image_delete_by_id", post_data)
        .then(async (response) => {
          return response;
        });
    }
    catch (err) {
      console.error(
        "Exception occurred at imageDelete() and Exception:",
        err.message
      );
    }
  },
};

export { Images };
